
import Api from "@/services/Api";
import { defineComponent, Ref, ref, onMounted, watch } from "vue";
import type { PropType } from "vue";
import useAlert from '@/composables/Alert'
import useEmitter from "@/composables/Emmiter";



export default defineComponent({
  name: "ModalLogProduto",
  components: {
  },
  props: {
    log: Object as PropType<any>,
    fechaModal: Function,
    abreModal: Function,
  },

  setup(props) {


    const log: Ref<any> = ref({});


    function fechaModal(){

      log.value = {};
      props.fechaModal && props.fechaModal()

    }

    function abreModal(){

      log.value = { ...props.log } 

      // console.log(log.value)
    
    }





    


    // abrindo modal com usuario
    watch(() => props.log, async () => { abreModal() }, { deep: true })

    onMounted(async()=>{

    })
   
    return {
      log,
      fechaModal,
    };
  },
});
