
import { ref, onMounted, onUnmounted, defineEmits, watch, Ref } from "vue";
import { defineComponent } from "vue";
import useEmitter from "@/composables/Emmiter";
import Api from "../../services/Api";
import s3 from '../../services/S3Service'
import useAlert from "../../composables/Alert";
import { Modal } from "bootstrap";
import ModalLogProduto from "./components/ModalLogProduto.vue";

export default defineComponent({

  name: "LogProdutos",
  
  components: {
    ModalLogProduto,
  },

  setup() {
    const emitter = useEmitter();

    const logProdutoModal: Ref<any> = ref()
    const logSelecionado: Ref<any> = ref()

    const limite = ref(30);
    const pagina: Ref<any> = ref(null);

    const filtro: Ref<any> = ref({ loja: [], automocao: [], comErro: null });

    const loadLog: Ref<boolean> = ref(false);

    const arrayLog: Ref<any> = ref([]);












    async function buscaLogPaginado(paginaParam: number, filtrou: boolean = false) {

      try {
        
        loadLog.value = true;
  
        const { data } = await Api.get("produtoIntegracaoPaginado", { pagina: paginaParam, limite: limite.value, ...filtro.value });
  
        loadLog.value = false;
  
        pagina.value = paginaParam;
  
        if(filtrou) arrayLog.value = [];
  
        arrayLog.value = [ ...arrayLog.value, ...data.logs ];

      } catch (error) { loadLog.value = false; }


    }


    emitter.on("FilterLogProdutos", (props: any) => {

      filtro.value = props

      buscaLogPaginado(1, true);

    });


    ///////////////////// MODAL PRODUTO LOG /////////////////////

    function abreModal(log?) {

      logSelecionado.value = log || {}
      logProdutoModal.value.show()

    }

    async function fechaModal() {
      
      logSelecionado.value = {}
      logProdutoModal.value.hide()

    }

    ///////////////////// MODAL PRODUTO LOG /////////////////////


    onMounted(() => {

      logProdutoModal.value = new Modal(document.querySelector(`#ModalLogProduto`));

      const observer = new IntersectionObserver(entries => {
        if(entries[0].isIntersecting) buscaLogPaginado(pagina.value ? pagina.value + 1 : 1);
      }).observe(document.querySelector("#load") as any);


    })




    return {

      arrayLog,
      loadLog,
      buscaLogPaginado,
      pagina,
      abreModal,
      fechaModal,
      logSelecionado,
      history

    }



  }

});
